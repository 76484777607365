exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-destinations-jsx": () => import("./../../../src/pages/destinations.jsx" /* webpackChunkName: "component---src-pages-destinations-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-hotel-jsx": () => import("./../../../src/templates/hotel.jsx" /* webpackChunkName: "component---src-templates-hotel-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

